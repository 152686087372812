<template>
    <div class="level_index level_indexII">

        <div class="search">

            <el-form :inline="true">

                <el-form-item label="组织机构">

                    <!-- <el-select clearable v-model="wasteInfo.dep_id" placeholder="请选择组织机构" class="SelfSelect">
                        <el-option :label="item.name" :value="item.id" v-for="item in depList"
                            :key="item.id"></el-option>
                    </el-select> -->

                    <el-cascader v-model="wasteInfo.dep_id" placeholder="请选择组织机构" :options="depList" filterable
                        clearable :props="{
                        label:'name', children:'children',checkStrictly:'true',value:'id' }"></el-cascader>

                </el-form-item>

                <el-form-item label="选择日期">
                    <el-date-picker v-model="wasteInfo.create_time" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        style="height: 36px; display: flex;align-items: baseline;">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="getList()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>
                </el-form-item>

            </el-form>

        </div>

        <div class="FanfuTableBox">


            <div class="DataStatisticsCell" v-if="data.create">
                <p class="DSCTitle">医废转运汇总：共<span>{{data.create.total}}</span>包， 共<span>{{data.create.weight}}</span>KG。
                </p>
                <p class="DSCContent" :class="`DSCPoint${index+1}`" v-for="(item,index) in data.create.data"
                    :key="item.id">
                    {{item.item_name}}：<span>{{item.total}}</span>包，
                    <span>{{item.weight}}</span>KG。
                </p>
            </div>

            <div class="DataStatisticsCell" v-if="data.out">
                <p class="DSCTitle">医废出库汇总：共<span>{{data.out.total}}</span>包， 共<span>{{data.out.weight}}</span>KG。</p>
                <p class="DSCContent" :class="`DSCPoint${index+1}`" v-for="(item,index) in data.out.data"
                    :key="item.id">
                    {{item.item_name}}：<span>{{item.total}}</span>包，
                    <span>{{item.weight}}</span>KG。
                </p>
            </div>

            <div class="DataStatisticsCell" v-if="data.storage">
                <p class="DSCTitle">医废入库汇总：共<span>{{data.storage.total}}</span>包，
                    共<span>{{data.storage.weight}}</span>KG。</p>
                <p class="DSCContent" :class="`DSCPoint${index+1}`" v-for="(item,index) in data.storage.data"
                    :key="item.id">
                    {{item.item_name}}：<span>{{item.total}}</span>包，
                    <span>{{item.weight}}</span>KG。
                </p>
            </div>

            <div class="DataStatisticsCell" v-if="data.hospital">

                <p class="DSCSubTitle">
                    公立中心医院共<span>{{data.hospital.gongli_total}}</span>家，民营机构共<span>{{data.hospital.mingying_total}}</span>家。
                </p>

                <p class="DSCTitle">公立医院：共<span>{{data.hospital.gongli.total}}</span>包，
                    共<span>{{data.hospital.gongli.weight}}</span>KG。</p>

                <p class="DSCContent" :class="`DSCPoint${index+1}`" v-for="(item,index) in data.hospital.gongli.data"
                    :key="item.id">
                    {{item.item_name}}：<span>{{item.total}}</span>包，
                    <span>{{item.weight}}</span>KG。
                </p>

                <div class="DSCLine"></div>

                <p class="DSCTitle">民营医院：共<span>{{data.hospital.minying.total}}</span>包，
                    共<span>{{data.hospital.minying.weight}}</span>KG。</p>

                <p class="DSCContent" :class="`DSCPoint${index+1}`" v-for="(item,index) in data.hospital.minying.data"
                    :key="item.id">
                    {{item.item_name}}：<span>{{item.total}}</span>包，
                    <span>{{item.weight}}</span>KG。
                </p>


            </div>

        </div>


    </div>
</template>

<script>
    import resizeDetector from 'element-resize-detector'

    import {
        mapState,
        mapMutations
    } from 'vuex'

    export default {
        components: {

        },
        data() {
            return {

                ItemTypeList: [], //医废类型

                depList: [], //机构

                levelList: [], //机构

                wasteInfo: {
                    create_time: "",
                    dep_id: ""
                },

                data: [],

            }
        },
        mounted() {
            this.modifyLayout();
        },

        beforeMount() {
            this.modifyLayout();
        },
        beforeUpdate() {
            this.modifyLayout();
        },
        updated() {
            this.modifyLayout();
        },
        created() {

            this.getList()

            this.getitemType()
            setTimeout(() => {
                this.ItemTypeList = this.ItemType
            }, 1000)


            // this.getdep()
            // setTimeout(() => {
            //     this.depList = this.DepList
            //     // this.depListHandle(this.DepList)
            // }, 1000)
            this.getdeptree()
            setTimeout(() => {
                this.depList = this.DepTree
            }, 1000)

            this.getlevel()
            setTimeout(() => {
                this.levelList = this.LevelList
            }, 1000)
        },

        computed: {
            ...mapState('m_item', [
                'LevelList',
                'PositionList',
                'DepList',
                'DepTree',
                'HealthList',
                'Province',
                'City',
                'District',
                'RoleList',
                'Depcate',
                'ItemType'
            ])
        },

        watch: {

            "wasteInfo": {
                deep: true, //深度监听设置为 true
                handler: function(newVal, oldVal) {
                    this.tosearch()
                }
            }

        },

        methods: {

            getList() {


                var a = {
                    ...this.wasteInfo
                }

                if (this.wasteInfo.create_time && this.wasteInfo.create_time.length > 1) {
                    a.create_time = this.wasteInfo.create_time.toString()
                }

                if (this.wasteInfo.dep_id && this.wasteInfo.dep_id.length > 0) {
                    a.dep_id = this.wasteInfo.dep_id.slice(-1).toString()
                }

                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", a);


                this.$http.get('/api/statistics/summary/', a).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {
                        this.data = res.data

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });

            },

            tosearch() {

                this.getList()
            },



            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;

                    var a = 141 + 60 + 0 + height - 30;
                    if ($(".level_indexII .FanfuTableBox")[0]) {

                        $(".level_indexII .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' +
                            a +
                            'px) !important');
                    }

                    var b = 141 + 60 + 70 + height - 30;
                    if ($(".level_indexII .el-table")[0]) {

                        $(".level_indexII .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }

                });

            },


        },

    }
</script>

<style lang="scss" scoped>
    .statisticsBox {
        // border: 1px red solid;
        // box-sizing: content-box;
        // height: 150px;
        margin-bottom: 10px;
        display: flex;

        position: relative;

        .statisticsCell {

            border: 1px solid #ECECEC;
            box-sizing: content-box;
            // height: 150px;
            flex: 2;
            margin-left: 10px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: nowrap;

            .title {
                // border: 1px red solid;
                border-left: 2px #6A83FC solid;
                box-sizing: content-box;

                // width: 58px;
                // height: 15px;
                // line-height: 20px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2F2F2F;
                margin: 5px 0px;
                padding: 0px 10px;
                position: absolute;
            }
        }

        .statisticsCell:nth-of-type(1) {
            flex: 3;
            margin-left: 0px;
        }
    }

    .SelfSelect {
        width: 150px;
        margin-right: 10px;
    }

    .OutputBox {
        // border: 1px red solid;
        // box-sizing: content-box;

        margin: -5px 0 10px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6A83FC;
        line-height: 20px;
        /* height: 30px; */
    }

    .OutputBox small {
        margin: 0 10px;
    }




    .FanfuTableBox {
        // border: 1px red solid;
        box-sizing: content-box;
        overflow: scroll;
        padding: 10px 10px 0px 10px;
    }


    .DataStatisticsCell {
        // border: 1px red solid;
        box-sizing: content-box;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 6px;
        box-shadow: 0 0 10px #0003;

        .DSCTitle {
            font-size: 14px;
            font-weight: bold;
            color: #303030;
            // border: 1px red solid;
            box-sizing: content-box;
            margin-bottom: 10px;

            span {
                color: #6A83FC;
                margin: 0 10px;
            }
        }

        .DSCSubTitle {
            font-size: 14px;
            color: #303030;
            // border: 1px red solid;
            box-sizing: content-box;
            margin-bottom: 15px;

            span {
                color: #6A83FC;
                margin: 0 10px;
            }
        }

        .DSCContent {
            font-size: 14px;
            font-weight: bold;
            color: #303030;
            // border: 1px red solid;
            box-sizing: content-box;
            color: #606060;
            margin: 0 0 10px 0;
            padding: 0 10px 0 20px;
            // display: inline-block;
            display: inline;
            position: relative;

            span {
                color: #6A83FC;
                margin: 0 10px;
            }

            &::before {
                content: "";
                position: absolute;
                left: 5px;
                // top: 50%;
                top: 3px;
                // transform: translateY(-50%) translateX(-25%);
                transform: translateX(-25%);
                width: 12px;
                height: 12px;
                border-radius: 50%;
                // background: #f00;
                // border: 1px red solid;
                // box-sizing: content-box;
            }

            &::after {
                content: "";
                position: absolute;
                left: 5px;
                // top: 50%;
                top: 9px;
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #fff;
                // border: 1px red solid;
                // box-sizing: content-box;
            }
        }


        .DSCPoint1 {
            &::before {
                background: #FF2444;
            }
        }

        .DSCPoint2 {
            &::before {
                background: #9062F7;
            }
        }

        .DSCPoint3 {
            &::before {
                background: #F89E19;
            }
        }

        .DSCPoint4 {
            &::before {
                background: #215DFB;
            }
        }

        .DSCPoint5 {
            &::before {
                background: #6DCC67;
            }
        }

        .DSCPoint6 {
            &::before {
                background: #00ffff;
            }
        }

        .DSCPoint7 {
            &::before {
                background: #ca89cc;
            }
        }








        .DSCLine {
            border-top: 2px #DEDEDE dotted;
            box-sizing: content-box;
            margin: 15px 0px 15px 0px;
        }

    }
</style>